import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { Container, Table, Button, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import './styles/adminConsole.scss';
import userDelete from './img/user_delete-white.svg';
import userDemote from './img/user_demote-white.svg';
import userPromote from './img/user_promote-white.svg';

function AdminConsole() {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedUserEmail, setSelectedUserEmail] = useState(''); // To display the selected user's email

    const getUsers = async () => {
        try {
            const response = await axios.get(`/admin/get/users`);
            setUsers(response.data.allUsers || []);
        } catch (error) {
            setErrorMessage('Failed to fetch users.');
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
        setSuccessMessage(''); // Clear success message
    };

    const handleOpenConfirmationModal = (userId, email, role, action) => {
        setSelectedUserId(userId);
        setSelectedUserEmail(email); // Store the user's email
        setSelectedUserRole(role);
        setActionType(action);
        setShowConfirmationModal(true);
    };

    const handleConfirmation = async (action) => {
        const actions = {
            'Delete': deleteUser,
            'PromoteToBasic': promoteToBasic,
            'PromoteToAdvanced': promoteToAdvanced,
            'DemoteToBasic': demoteToBasic,
            'DemoteToUser': demoteToUser,
        };
    
        if (actions[action]) {
            await actions[action](selectedUserId);
            setShowConfirmationModal(false);
        }
    };
    
    const notifyUser = (message) => {
        setSuccessMessage(message);
        setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    };

    const deleteUser = async (userId) => {
        try {
            await axios.delete(`/admin/delete/user/${userId}`);
            notifyUser(`${selectedUserEmail} deleted successfully.`);
            getUsers();
        } catch (error) {
            setErrorMessage(`Failed to delete User ${selectedUserEmail}.`);
        }
    };

    const promoteToBasic = async (userId) => {
        try {
            await axios.put(`/admin/promote/basic/${userId}`);
            notifyUser(`${selectedUserEmail} promoted to Basic.`);
            getUsers();
        } catch (error) {
            setErrorMessage(`Failed to promote User ${selectedUserEmail} to Basic.`);
        }
    };

    const promoteToAdvanced = async (userId) => {
        try {
            await axios.put(`/admin/promote/advanced/${userId}`);
            notifyUser(`User ${selectedUserEmail} promoted to Advanced.`);
            getUsers();
        } catch (error) {
            setErrorMessage(`Failed to promote User ${selectedUserEmail} to Advanced.`);
        }
    };

    const demoteToBasic = async (userId) => {
        try {
            await axios.put(`/admin/demote/basic/${userId}`);
            notifyUser(`${selectedUserEmail} demoted to Basic.`);
            getUsers();
        } catch (error) {
            setErrorMessage(`Failed to demote User ${selectedUserEmail} to Basic.`);
        }
    };

    const demoteToUser = async (userId) => {
        try {
            await axios.put(`/admin/demote/user/${userId}`);
            notifyUser(`${selectedUserEmail} demoted to User.`);
            getUsers();
        } catch (error) {
            setErrorMessage(`Failed to demote User ${selectedUserEmail} to User.`);
        }
    };

    const renderConfirmationModal = () => (
        <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
            <Modal.Header closeButton>
                <Modal.Title>{actionType.includes('Promote') ? 'Promotion Options' : actionType.includes('Demote') ? 'Demotion Options' : 'Delete User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {actionType.includes('Promote') && `Are you sure you want to promote ${selectedUserEmail} to?`}
                    {actionType.includes('Demote') && `Are you sure you want to demote ${selectedUserEmail} to?`}
                    {actionType === 'Delete' && `Are you sure you want to delete ${selectedUserEmail}? This action cannot be undone.`}
                </p> {/* Notification text */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseConfirmationModal}>Cancel</Button>
                {actionType === 'Delete' ? (
                    <Button variant="danger" onClick={() => handleConfirmation('Delete')}>Delete</Button> // Add this line
                ) : (
                    renderPromotionOptions() // Renders promote/demote buttons next to Cancel
                )}
            </Modal.Footer>
        </Modal>
    );
    
    
    const renderPromotionOptions = () => {
        if (actionType.includes('Promote')) {
            return (
                <>
                    {selectedUserRole === 'user' && (
                        <>
                            <Button variant="success" onClick={() => handleConfirmation('PromoteToBasic')}>Promote to Basic</Button>
                            <Button variant="success" onClick={() => handleConfirmation('PromoteToAdvanced')}>Promote to Advanced</Button>
                        </>
                    )}
                    {selectedUserRole === 'basic' && (
                        <Button variant="success" onClick={() => handleConfirmation('PromoteToAdvanced')}>Promote to Advanced</Button>
                    )}
                </>
            );
        } else if (actionType.includes('Demote')) {
            return (
                <>
                    {selectedUserRole === 'advanced' && (
                        <>
                            <Button variant="warning" onClick={() => handleConfirmation('DemoteToBasic')}>Demote to Basic</Button>
                            <Button variant="warning" onClick={() => handleConfirmation('DemoteToUser')}>Demote to User</Button>
                        </>
                    )}
                    {selectedUserRole === 'basic' && (
                        <Button variant="warning" onClick={() => handleConfirmation('DemoteToUser')}>Demote to User</Button>
                    )}
                </>
            );
        }
    };
    

    const renderActionButtons = (user) => {
        return (
            <>
                {user.role === 'user' && (
                    <>
                        <Button className='custom-button-promote' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'user', 'Promote')}>
                            <img src={userPromote} alt="Promote" className="button-icon" />
                        </Button>
                        <Button className='custom-button-delete' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'user', 'Delete')}>
                            <img src={userDelete} alt="Delete" className="button-icon" />
                        </Button>
                    </>
                )}
                {user.role === 'basic' && (
                    <>
                        <Button className='custom-button-promote' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'basic', 'Promote')}>
                            <img src={userPromote} alt="Promote" className="button-icon" />
                        </Button>
                        <Button className='custom-button-demote' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'basic', 'Demote')}>
                            <img src={userDemote} alt="Demote" className="button-icon" />
                        </Button>
                        <Button className='custom-button-delete' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'basic', 'Delete')}>
                            <img src={userDelete} alt="Delete" className="button-icon" />
                        </Button>
                    </>
                )}
                {user.role === 'advanced' && (
                    <>
                        <Button className='custom-button-demote' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'advanced', 'Demote')}>
                            <img src={userDemote} alt="Demote" className="button-icon" />
                        </Button>
                        <Button className='custom-button-delete' onClick={() => handleOpenConfirmationModal(user.id, user.email, 'advanced', 'Delete')}>
                            <img src={userDelete} alt="Delete" className="button-icon" />
                        </Button>
                    </>
                )}
            </>
        );
    };
    
    

    return (
        <main>
            <NavigationBar />
            <Container fluid id='admin-console-container'>
                <div className="page-title">Admin Console</div>

                {/* Display error message */}
                {errorMessage && (
                    <Alert variant="danger">{errorMessage}</Alert>
                )}
                {/* Display success message */}
                {successMessage && (
                    <Alert variant="success">{successMessage}</Alert>
                )}

                <Table className='align-middle console-table'>
                    <thead className='text-center'>
                        <tr>
                            <th scope="col">User ID</th>
                            <th scope="col">User Email</th>
                            <th scope="col">User Role</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody className='table-group-divider text-center'>
                        {users.map(user => (
                            <tr key={user.id}>
                                <th scope="row">{user.id}</th>
                                <td className='user-info'>{user.email}</td>
                                <td className='user-info'>{user.role}</td>
                                <td className='user-actions'>
                                    {renderActionButtons(user)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table> 
            </Container>

            {/* Confirmation Modal */}
            {renderConfirmationModal()}
        </main>
    );
}

export default AdminConsole;
