import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/telegramVerify.scss';
import NavigationBar from './NavigationBar';


const TelegramVerifyPage = () => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleVerify = async (e) => {
        e.preventDefault();  // Prevent form submission
        try {
            setIsLoading(true);  // Start loading
            const requestData = { code };

            const response = await axios.post('/verify/telegram', requestData);
            console.log('Server response:', response.data);
            if (response.status === 201) { 
                setSuccessMessage('Verification successful! You will be redirected shortly.');
                setErrorMessage(''); 
                setCode(''); 
                setTimeout(() => {
                    navigate('/login');
                }, 2000); 
            } else {
                setErrorMessage(response.data.message || 'Invalid or expired code. Please try again.');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error during verification:', error);
            if (error.response) {
                setErrorMessage(error.response.data.message || 'An error occurred. Please try again later.');
            } else {
                
                setErrorMessage('An error occurred. Please try again later.');
            }
            setSuccessMessage('');
        } finally {
            setIsLoading(false);  
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="container-fluid" id="telegram-verification-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center" id="verify-telegram-title">
                            Verify your <span>Telegram</span> account
                        </h2>

                        <ol>
                            <li>
                                Download and install the <a href="https://telegram.org">Telegram app</a> on your phone or computer or visit <a href="https://web.telegram.org">Telegram Web</a>.
                            </li>
                            <li>Sign in to your <span>Telegram</span> account.</li>
                            <li>In the search bar look for <strong>@StalkerWindBOT</strong>.</li>
                            <li>Type the command <span>/verify</span> in the chat, and you'll receive a verification code. Enter that code below.</li>
                        </ol>

                        <form onSubmit={handleVerify}>
                            <div className="form-outline">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="verificationCode"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder="Input verification code..."
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="d-flex justify-content-center" id="btn-container">
                                <button type="submit" className="btn" id="btn-form-submit" disabled={isLoading}>
                                    {isLoading ? 'Verifying...' : 'Verify'}
                                </button>
                            </div>

                            {errorMessage && (
                                <div className="error-message text-center mt-3">
                                    {errorMessage}
                                </div>
                            )}

                             {successMessage && (
                                <div className="success-message text-center mt-3">
                                    {successMessage}
                                </div>
                            )}
                            {/* {successMessage && <div className="success-message">{successMessage}</div>} */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TelegramVerifyPage;