import React, { useState } from 'react';
import NavigationBar from './NavigationBar'; 
import './styles/feedback.scss';

function FeedbackForm({ isLoggedIn, userEmail }) {
    const [rating, setRating] = useState(3); 
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState(userEmail || '');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const feedbackData = {
            email: isLoggedIn ? userEmail : email, 
            rating,
            feedback,
        };

        try {
            const response = await fetch('/feedback/submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(feedbackData),
            });

            if (response.ok) {
                setSuccessMessage(true); 
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to submit feedback. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('An error occurred while submitting feedback.');
        }
    };

    const handleCloseSuccessMessage = () => {
        window.location.href = '/'; 
    };

    return (
        <div>
            <NavigationBar isLoggedIn={isLoggedIn} />
            <div className="feedback-page">
                {!successMessage && (
                    <>
                        <h2>Share your Feedback</h2>
                        <p>Your input helps us improve. We'd love to hear from you!</p>
                    </>
                )}

                {successMessage ? (
                    <div className="success-message">
                        <h2>Thank you for your feedback!</h2>
                        <p>We appreciate you taking the time to share your thoughts with us. 
                        Your feedback helps us improve our service and provide a better experience for all users. 
                        We’ll review your feedback and get back to you if necessary. 
                        If you have any further comments or questions, feel free to reach out to us.</p>
                        <button onClick={handleCloseSuccessMessage}>Close</button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="rating-system">
                            <label>Rate your experience</label>
                            <div className="star-rating">
                                {[...Array(5)].map((_, index) => (
                                    <div className="star-container" key={index}>
                                        <span
                                            className={`star ${rating >= index + 1 ? 'selected' : ''}`}
                                            onClick={() => setRating(index + 1)}
                                        >
                                            ★
                                        </span>
                                        <span className="tooltip">{index + 1}/5</span> {/* Tooltip */}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {!isLoggedIn && (
                            <div className="email-input">
                                <label>Enter your email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="youremail@example.com"
                                />
                            </div>
                        )}

                        <div className="feedback-input">
                            <label>Leave a comment</label>
                            <textarea
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder="Tell us about your experience..."
                            />
                        </div>

                        {error && <p className="error-message">{error}</p>}

                        <button type="submit" className="submit-button">Submit Feedback</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default FeedbackForm;
