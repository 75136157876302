import React, { useState, useEffect, useCallback } from 'react';
import NavigationBar from './NavigationBar';
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { InfoCircle, Gear, Lock, Envelope, Telegram } from 'react-bootstrap-icons';
import './styles/profile.scss';

function ProfilePage() {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [filterCount, setFilterCount] = useState(0);
    const [maxFilters, setMaxFilters] = useState(0);
    const [showMenu, setShowMenu] = useState(false); // State to control the dropdown menu
    const navigate = useNavigate(); 

    // Function to fetch user details
    const getUser = useCallback(async () => {
        try {
            const response = await axios.get('/api/get/user', { withCredentials: true });
            if (response.status === 200) {
                const userData = response.data; 
                setEmail(userData.email);
                setRole(userData.role);
                setMaxFilters(getMaxFilters(userData.role)); 
            }
        } catch (err) {
            console.error(err);
            alert('Kļūda: ' + (err.response?.data.message || 'Nezināma kļūda. Lūdzu, mēģiniet vēlreiz.'));
        }
    }, []);

    // Function filter count
    const getFilterCount = useCallback(async () => {
        try {
            const response = await axios.get('/filters/get/count', { withCredentials: true }); 
            if (response.status === 200) {
                setFilterCount(response.data.count); 
            }
        } catch (err) {
            console.error(err);
            alert('Kļūda: ' + (err.response?.data.message || 'Nezināma kļūda. Lūdzu, mēģiniet vēlreiz.'));
        }
    }, []);

    // Function to check if user is authenticated
    const checkAuthStatus = useCallback(async () => {
        try {
            const response = await axios.get('/api/auth/status', { withCredentials: true });
            if (!response.data.isLoggedIn) {
                navigate('/login');
            }
        } catch (err) {
            console.error('Error checking auth status:', err);
            navigate('/login'); // Redirect to login if there's an error
        }
    }, [navigate]);

    useEffect(() => {
        checkAuthStatus(); 
        getUser(); 
        getFilterCount(); 
    }, [checkAuthStatus, getUser, getFilterCount]); 

    const getMaxFilters = (role) => {
        switch (role) {
            case 'basic':
                return 5;
            case 'advanced':
                return 10;
            case 'admin':
                return 20;
            default:
                return 0;
        }
    };

    const renderPlanBenefits = () => {
        switch (role) {
            case 'user':
                return 'The User plan provides limited access, with no additional features or benefits.';
            case 'basic':
                return 'The Basic plan allows the creation and usage of up to 5 SS filters.';
            case 'advanced':
                return 'The Advanced plan offers the ability to create and manage up to 10 SS filters.';
            case 'admin':
                return 'Admins have unrestricted access to all features and functionalities.';
            default:
                return '';
        }
    };

    const toggleMenu = () => setShowMenu(!showMenu);

    return (
        <div>
            <NavigationBar />
            <Container fluid id='profile-container'>
                <Card>
                    <Card.Body>
                        <div className='profile-title'>
                            PROFILE
                            <div className='settings-icon-container'>
                                <Gear className='settings-icon' onClick={toggleMenu} />
                                {showMenu && (
                                    <div className="settings-dropdown">
                                        <Button variant="secondary" className="w-100 disabled-button">
                                            <Lock className="me-2" />
                                            Change Password
                                        </Button>
                                        <Button variant="secondary" className="w-100 disabled-button">
                                            <Envelope className="me-2" />
                                            Change Email
                                        </Button>
                                        <Button variant="secondary" className="w-100 disabled-button">
                                            <Telegram className="me-2" />
                                            Change Telegram Account
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Card.Title>Email</Card.Title>
                        <span className='email-underline'></span>
                        <Card.Text>{email}</Card.Text>
                        <Card.Title>
                            Current Plan
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{renderPlanBenefits()}</Tooltip>}
                            >
                                <InfoCircle className="ms-2 info-icon" />
                            </OverlayTrigger>
                        </Card.Title>
                        <span className='plan-underline'></span>
                        <Card.Text>{role}</Card.Text>
                        <Card.Title>Active Filters</Card.Title>
                        <span className='info-underline'></span>
                        <Card.Text>{`${filterCount}/${maxFilters} active filters`}</Card.Text>

                        <Row>
                            <Col xs={12} lg={8} id='products-btn-con'>
                                <Button
                                    variant="primary"
                                    as={Link}
                                    to="/products"
                                    className="w-100" id='btn-products'>
                                    Products
                                </Button>
                            </Col>
                            <Col xs={12} lg={4} id='signout-btn-con'>
                                <Button
                                    variant="danger"
                                    as="a"
                                    href="/api/auth/signout"
                                    className="w-100" id='btn-signout'>
                                    Sign Out
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default ProfilePage;
