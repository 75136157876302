import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/authForm.scss';
import NavigationBar from './NavigationBar';

function SignUpPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        repeatPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault(); 
        setIsRegistering(true);
        setErrorMessage(''); // Clear previous error

        try {
            const response = await axios.post('/auth/signup', formData, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.status === 201) {
                navigate('/verify/telegram');
            }
        } catch (error) {
            setIsRegistering(false);
            if (error.response && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Registration failed. Please try again.');
            }
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="container-fluid" id='form-container'>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-uppercase text-center" id='signup-title'>Create an account</h2>
                        <form onSubmit={handleRegister}>
                            
                            <div className="form-outline">
                                <label className="form-label">Email</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name='email'
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    required
                                />
                            </div>

                            <div className="form-outline">
                                <label className="form-label">Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name='password'
                                    value={formData.password} 
                                    onChange={handleChange}
                                    required 
                                />
                            </div>

                            <div className="form-outline">
                                <label className="form-label">Confirm Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name='repeatPassword'
                                    value={formData.repeatPassword} 
                                    onChange={handleChange}
                                    required 
                                />
                            </div>

                            <div className="d-flex justify-content-center" id='btn-container'>
                                <button 
                                    type="submit" 
                                    className="btn" 
                                    id='btn-form-submit'
                                    disabled={isRegistering}
                                >
                                    {isRegistering ? 'Registering...' : 'Sign Up'}
                                </button>
                            </div>

                            {errorMessage && (
                                <div className="error-message text-center mt-3">
                                    {errorMessage}
                                </div>
                            )}

                            <p className="text-center" id='footer-text'>Already have an account?
                                <Link to='/login' className="nav-link"><u>Login here</u></Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpPage;
