
import React from 'react';
import './styles/SessionExpire.scss';
const SessionExpiredModal = ({ onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Session Expired</h2>
          <p>Your session has expired. Please log in again.</p>
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    );
  };
export default SessionExpiredModal;
