// PageSwitcher.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './styles/switch.scss';

const PageSwitcher = () => {
    const location = useLocation();

    // Define state for disabling buttons
    const [isMotorcyclesDisabled] = useState(true);  // Initialize as true to disable
    const [isSparePartsDisabled] = useState(true);   // Initialize as true to disable

    // Determine which page is active
    const isFiltersActive = location.pathname === '/filters';
    const isMotorcyclesActive = location.pathname === '/motorcycles';
    const isSparePartsActive = location.pathname === '/spare-parts';

    return (
        <div className="page-switch-buttons" style={{ marginBottom: '20px' }}>
            <Link to="/filters">
                <Button className={isFiltersActive ? 'active-button' : 'inactive-button'}>
                    Cars
                </Button>
            </Link>
            <Link to="/motorcycles">
                <Button 
                    className={isMotorcyclesActive ? 'active-button' : 'inactive-button'} 
                    disabled={isMotorcyclesDisabled}  // Disabled based on state
                >
                    Motorcycles
                </Button>
            </Link>
            <Link to="/spare-parts">
                <Button 
                    className={isSparePartsActive ? 'active-button' : 'inactive-button'} 
                    disabled={isSparePartsDisabled}  // Disabled based on state
                >
                    Spare Parts
                </Button>
            </Link>
        </div>
    );
};

export default PageSwitcher;
