// src/LoginPage.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import axios from 'axios';
import './styles/authForm.scss';

function LoginPage({ onLogin }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/auth/login', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                onLogin(); 
                navigate('/profile');
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Login failed. Please check your connection and try again.');
            }
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="container-fluid" id='form-container'>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-uppercase text-center" id='login-title'>Login</h2>
                        <form onSubmit={handleSubmit}>

                            <div className="form-outline">
                                <label className="form-label">Email</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="form-outline">
                                <label className="form-label">Password</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name='password'
                                    value={formData.password}
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="d-flex justify-content-center" id='btn-container'>
                                <button type="submit" className="btn" id='btn-form-submit'>Login</button>
                            </div>

                            {errorMessage && (
                                <div className="error-message text-center mt-3">
                                    {errorMessage}
                                </div>
                            )}
                            
                            <p className="text-center" id='footer-text'>
                                Don't have an account? 
                                <Link to='/signup' className="nav-link"><u>Sign Up here</u></Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
