import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/esm/Container';
import FiltersForm from './FiltersForm';
import NavigationBar from './NavigationBar';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import './styles/filters.scss';
import PageSwitcher from './PageSwitcher'; 

function FiltersPage() {
  const [filterForms, setFilterForms] = useState([]);
  const [filters, setFilters] = useState([]);
  const [role, setRole] = useState('');
  const [formCount, setFormCount] = useState(0);
  const [noFilters, setNoFilters] = useState(true);

  const fetchFormCount = async () => {
    try {
      const response = await axios.get('/filters/get/count');
      return response.data.count;
    } catch (error) {
      console.error('Error fetching form count:', error);
      return null;
    }
  };

  useEffect(() => {
    // Fetch filters data from the server
    const fetchFilters = async () => {
      try {
        const response = await axios.get('/filters/get/filters');
        const filtersData = response.data;
        const formattedFilters = Object.keys(filtersData).map(filterId => ({
          filterId,
          ...filtersData[filterId][0] // Assuming each filterGroupId only has one filter group
        }));
        if (formattedFilters.length !== 0) {
          setFilters(formattedFilters); // Set the state with the fetched filters
          console.log(formattedFilters);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    const fetchFormData = async () => {
      try {
        const count = await fetchFormCount();
        if (count !== null) {
          setFormCount(count);
        }
      } catch (error) {
        console.error('Error fetching filter count:', error);
      }
    };

    const getUser = async () => {
      try {
        const response = await axios.get('/api/get/user', { withCredentials: true });
        const userData = response.data;
        setRole(userData.role);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchFilters()
      .then(fetchFormData)
      .then(getUser)
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const getFilterLimit = () => {
    if (role === 'admin') {
      return 20; // Admin can have up to 20 filter forms
    } else if (role === 'advanced') {
      return 10; // Advanced user can have up to 10 filter forms
    } else if (role === 'basic') {
      return 5; // Basic user can have up to 5 filter forms
    } else {
      return 0; // Default limit (no limit)
    }
  };

  const handleAddFilterClick = () => {
    if (noFilters) {
      setNoFilters(false);
    }
    const filterLimit = getFilterLimit();
    if (formCount < filterLimit) {
      const currentMonth = new Date().toISOString().slice(0, 7); // YYYY-MM format
      const updatedFilters = filters.map(filter => ({
        ...filter,
        checkup: filter.validCheckup && !filter.checkup ? currentMonth : filter.checkup
      }));

      setFilterForms([...filterForms, { id: Date.now(), filters: updatedFilters }]); // Add unique ID based on timestamp
      setFormCount(formCount + 1); // Increment form count
    }
  };

  return (
    <div>
      <NavigationBar />
      <Container fluid id='filter-page-container'>
        
        {/* Place PageSwitcher above the title */}
        <PageSwitcher />
  
        {/* Title of the page */}
        {/* <div className="page-title">Filters for ss.com</div> */}
  
        {(role !== 'basic' && role !== 'admin' && role !== 'advanced') && ( 
          <Alert id='restricted-alert'>
            <Alert.Heading>Adding filters restricted</Alert.Heading>
            <hr />
            <p>Upgrade your plan to add filters!</p>
          </Alert>
        )}
  
        {(role === 'basic' || role === 'advanced' || role === 'admin') && (formCount === 0 && noFilters) && (
          <Alert>
            <Alert.Heading>Psst...Try a Filter!</Alert.Heading>
            <hr />
            <p>Ready to find exactly what you need? Let's add some filters!</p>
          </Alert>
        )}
  
        <Accordion>
          {/* Render each filter form */}
          {(role === 'basic' || role === 'advanced' || role === 'admin') && filters.map(filter => (
            <FiltersForm key={filter.filterId} filterData={filter} />
          ))}
  
          {filterForms.map(form => (
            <FiltersForm key={form.id} filterData={form.filters} />
          ))}
        </Accordion>
  
        {(role === 'basic' || role === 'advanced' || role === 'admin') && (
          <div className='btn-new-container'>
            <Button
              disabled={formCount >= getFilterLimit()}
              onClick={handleAddFilterClick}>
              + Add new filter
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
  
}

export default FiltersPage;
