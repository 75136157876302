import React from 'react';
import NavigationBar from './NavigationBar'; 
import PageSwitcher from './PageSwitcher';
function SparePartsPage() {
  return (
    <div>
         <NavigationBar />
         <PageSwitcher />
      <h1>Spare Parts</h1>
      <p>Find the spare parts you need for your vehicles.</p>
    </div>
  );
}

export default SparePartsPage;
