import React, { useState, useEffect, useRef  } from 'react';
import { Accordion, Row, Col, Button, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import carData from './ssData/dropdownData/carData.json';
import './styles/filters.scss';

function FiltersForm({ filterData }) {
const [showEngineDropdown, setShowEngineDropdown] = useState(false);
const [showBodyTypeDropdown, setShowBodyTypeDropdown] = useState(false);
const [showCarModelDropdown, setShowCarModelDropdown] = useState(false);
const [showColorDropdown, setShowColorDropdown] = useState(false);
const [showAreaDropdown, setShowAreaDropdown] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [filterId, setFilterId] = useState(null);
     
    const [filters, setFilters] = useState({
        filterName: 'Filter',
        priceMin: null,
        priceMax: null,
        yearMin: '-',
        yearMax: '-',
        volumeMin: null,
        volumeMax: null,
        runMin: null,
        runMax: null,
        carBrand: "0",
        gearBox: "0",
        checkup: null,
        dealType: "0",
        carModel: [],  
        engine: [],    
        bodyType: [],  
        color: [],     
        area: []  
    });

     // Dropdown refs for outside click detection
  const carModelDropdownRef = useRef(null);
  const engineDropdownRef = useRef(null);
  const bodyTypeDropdownRef = useRef(null);
  const colorDropdownRef = useRef(null);
  const areaDropdownRef = useRef(null);

  // Handle outside clicks for all dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (carModelDropdownRef.current && !carModelDropdownRef.current.contains(event.target)) {
        setShowCarModelDropdown(false);
      }
      if (engineDropdownRef.current && !engineDropdownRef.current.contains(event.target)) {
        setShowEngineDropdown(false);
      }
      if (bodyTypeDropdownRef.current && !bodyTypeDropdownRef.current.contains(event.target)) {
        setShowBodyTypeDropdown(false);
      }
      if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
        setShowColorDropdown(false);
      }
      if (areaDropdownRef.current && !areaDropdownRef.current.contains(event.target)) {
        setShowAreaDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Add and remove for Car Model
  const handleAddCarModel = (event) => {
    const { value } = event.target;
    if (!filters.carModel.includes(value)) {
        setFilters(prevFilters => ({
            ...prevFilters,
            carModel: [...prevFilters.carModel, value],
        }));
        setShowCarModelDropdown(false); // Close the Car Model dropdown
    }
};

const handleRemoveCarModel = (modelToRemove) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        carModel: prevFilters.carModel.filter(model => model !== modelToRemove),
    }));
};

// Add and remove for Engine
const handleAddEngine = (event) => {
    const { value } = event.target;
    if (!filters.engine.includes(value)) {
        setFilters(prevFilters => ({
            ...prevFilters,
            engine: [...prevFilters.engine, value],
        }));
        setShowEngineDropdown(false);  // Close the Engine dropdown
    }
};

const handleRemoveEngine = (engineToRemove) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        engine: prevFilters.engine.filter(engine => engine !== engineToRemove),
    }));
};

// Add and remove for Body Type
const handleAddBodyType = (event) => {
    const { value } = event.target;
    if (!filters.bodyType.includes(value)) {
        setFilters(prevFilters => ({
            ...prevFilters,
            bodyType: [...prevFilters.bodyType, value],
        }));
        setShowBodyTypeDropdown(false);  // Close the Body Type dropdown
    }
};

const handleRemoveBodyType = (bodyTypeToRemove) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        bodyType: prevFilters.bodyType.filter(body => body !== bodyTypeToRemove),
    }));
};

// Add and remove for Color
const handleAddColor = (event) => {
    const { value } = event.target;
    if (!filters.color.includes(value)) {
        setFilters(prevFilters => ({
            ...prevFilters,
            color: [...prevFilters.color, value],
        }));
        setShowColorDropdown(false);  
    }
};

const handleRemoveColor = (colorToRemove) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        color: prevFilters.color.filter(color => color !== colorToRemove),
    }));
};

// Add and remove for Area
const handleAddArea = (event) => {
    const { value } = event.target;
    if (!filters.area.includes(value)) {
        setFilters(prevFilters => ({
            ...prevFilters,
            area: [...prevFilters.area, value],
        }));
        setShowAreaDropdown(false);  
    }
};

const handleRemoveArea = (areaToRemove) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        area: prevFilters.area.filter(area => area !== areaToRemove),
    }));
};

    

const toggleEngineDropdown = () => {
    setShowEngineDropdown(prevState => !prevState);
};

const toggleBodyTypeDropdown = () => {
    setShowBodyTypeDropdown(prevState => !prevState);
};

const toggleCarModelDropdown = () => {
    setShowCarModelDropdown(prevState => !prevState);
};

const toggleColorDropdown = () => {
    setShowColorDropdown(prevState => !prevState);
};

const toggleAreaDropdown = () => {
    setShowAreaDropdown(prevState => !prevState);
};

    const generateYears = (startYear, endYear) => {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return years;
    };

    const yearsMin = filters.yearMax === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(1900, filters.yearMax).concat('-');
    const yearsMax = filters.yearMin === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(filters.yearMin, new Date().getFullYear()).concat('-');

    const generateCheckUpMonths = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setFullYear(endDate.getFullYear() + 2);
        endDate.setMonth(endDate.getMonth() + 1);

        const checkUpMonths = [];

        while (currentDate < endDate) {
            const monthText = currentDate.toLocaleDateString('default', { month: 'short', year: 'numeric' });
            const monthValue = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
            checkUpMonths.push({ text: monthText, value: monthValue });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return checkUpMonths;
    };

    useEffect(() => {
        if (filterData) {
            setFilters({
                filterName: filterData.filterName || 'Filter',
                priceMin: filterData.priceMin || null,
                priceMax: filterData.priceMax || null,
                yearMin: filterData.yearMin || '-',
                yearMax: filterData.yearMax || '-',
                volumeMin: filterData.volumeMin || null,
                volumeMax: filterData.volumeMax || null,
                runMin: filterData.runMin || null,
                runMax: filterData.runMax || null,
                carBrand: filterData.carBrand || "0",
                gearBox: filterData.gearBox || "0",
                checkup: filterData.checkup || null,
                dealType: filterData.dealType || "0",
                carModel: filterData.carModel || [],  
                engine: filterData.engine || [],
                bodyType: filterData.bodyType || [],
                color: filterData.color || [],
                area: filterData.area || []
            });
            setFilterId(filterData.filterId);  
        }
    }, [filterData]);

   const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedFilters = {
        ...filters,
        [name]: value === "0" ? null : value, 
    };
    

    if (name === 'carBrand' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            carModel: []  
        };
    }
    
    if (name === 'engine' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            engine: []  
        };
    }
    
    if (name === 'bodyType' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            bodyType: [] 
        };
    }
    
    if (name === 'color' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            color: [] 
        };
    }
    
    if (name === 'area' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            area: [] 
        };
    }
    
    setFilters(updatedFilters);
};

    

    const handleValidation = async () => {
        try {
            setIsSaving(true);
            const url = filterId == null ? '/filters/new' : `/filters/update/${filterId}`;
            const method = filterId == null ? 'post' : 'put';  // Choose between POST (create) and PUT (update)
            const response = await axios[method](url, { filters  }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.status === 200) {
                setErrorMessage(false);
                window.location.reload();  // Reload the page to reflect updated data
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Unexpected error occurred during save.');
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        if (filterId != null) {
            try {
                await axios.delete(`/filters/delete/${filterId}`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setShowDeleteModal(false);
                setIsDeleting(false);
                window.location.reload();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div>
            <div className="container-fluid" id='filters-container'>
                <form>
                    <Accordion.Item eventKey={filterId == null ? 0 : filterId}>
                        <Accordion.Header className="custom-accordion-header">{filters.filterName}</Accordion.Header>
                        <Accordion.Body>

                            {/* Filter Name */}
                            <div className="input-container">
                                <label htmlFor="filterNameInput" className="floating-label">Filter name:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Give your filter group a name so you can easily identify it later. For example, you can name it 'Family Cars' or 'SUVs'.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Control 
                                        type="text" 
                                        placeholder="My custom filter name" 
                                        name='filterName' 
                                        value={filters.filterName} 
                                        onChange={handleChange} 
                                        maxLength={40} 
                                        required 
                                    />
                                </div>
                            </div>

                    {/* Car Brand */}
<div className="input-container">
    <label htmlFor="brandSelect" className="floating-label">Car brand:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Choose the brand (make) of the car you're interested in, such as Toyota, BMW, or Ford.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Form.Select
            name="carBrand"
            value={filters.carBrand}
            onChange={(e) => setFilters({ ...filters, carBrand: e.target.value, carModel: [] })} 
        >
            {Object.entries(carData.car_brand).map(([key, { display, value }]) => (
                <option key={key} value={value}>{display}</option>
            ))}
        </Form.Select>
    </div>
</div>

{/* Car Model */}
<div className="input-container">
    <label htmlFor="carModelSelect" className="floating-label">Car Model:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Select the specific model of the car you want, like 'Corolla' for Toyota or 'Civic' for Honda.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Button className="custom-outline-btn" onClick={toggleCarModelDropdown}>+</Button>
    </div>
</div>

{/* Dropdown list */}
{showCarModelDropdown && (
    <div ref={carModelDropdownRef} className="dropdown-container mt-2">
        <Form.Select onChange={handleAddCarModel}>
            {Object.entries(carData.car_brand[filters.carBrand].car_models)
                .filter(([modelValue]) => !filters.carModel.includes(modelValue))
                .map(([modelValue, modelDisplay]) => (
                    <option key={modelValue} value={modelValue}>
                        {modelDisplay}
                    </option>
                ))}
        </Form.Select>
    </div>
)}

{/* Selected models */}
{filters.carModel.length > 0 && (
    <div className="selected-models d-flex flex-wrap mt-3">
        {filters.carModel.map((model, index) => (
            <div key={index} className="selected-model d-flex align-items-center mb-2">
                <span>{carData.car_brand[filters.carBrand].car_models[model]}</span>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleRemoveCarModel(model)}
                >
                    &times;
                </button>
            </div>
        ))}
    </div>
)}

                            {/* Price */}
                            <div className="input-container">
                                <label htmlFor="priceInput" className="floating-label">Price:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Set the price range you're looking for. The 'From' field is the minimum price, and the 'To' field is the maximum price you're willing to pay.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingPriceFrom" label="From">
                                        <Form.Control id='cool-control-left' type="number" placeholder="0" name='priceMin' value={filters.priceMin} onChange={handleChange} min="0" />
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingPriceTo" label="To">
                                        <Form.Control id='cool-control-right' type="number" placeholder="0" name='priceMax' value={filters.priceMax} onChange={handleChange} min="0" />
                                    </FloatingLabel>
                                </div>
                            </div>

                            {/* Year */}
                            <div className="input-container">
                                <label htmlFor="yearInput" className="floating-label">Year:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Choose the year range of the car. The 'From' field is the oldest year you're okay with, and the 'To' field is the newest year.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingYearFrom" label="From">
                                        <Form.Select id='cool-control-left' name='yearMin' value={filters.yearMin} onChange={handleChange}>
                                            {yearsMin.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingYearTo" label="To">
                                        <Form.Select id='cool-control-right' name='yearMax' value={filters.yearMax} onChange={handleChange}>
                                            {yearsMax.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>
                            </div>

                            {/* Volume */}
                            <div className="input-container">
                                <label htmlFor="volumeInput" className="floating-label">Engine volume:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Enter the engine volume range (measured in liters). 'From' is the smallest engine size, and 'To' is the largest. For example, 1.5L to 3.0L.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingVolumeFrom" label="From">
                                        <Form.Control id='cool-control-left' type="number" step=".1" placeholder="0" name='volumeMin' value={filters.volumeMin} onChange={handleChange} />
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingVolumeTo" label="To">
                                        <Form.Control id='cool-control-right' type="number" step=".1" placeholder="0" name='volumeMax' value={filters.volumeMax} onChange={handleChange} />
                                    </FloatingLabel>
                                </div>
                            </div>

                          {/* Engine Type */}
<div className="input-container">
    <label htmlFor="engineSelect" className="floating-label">Engine Type:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Select the type of engine the car uses, such as diesel or electric.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Button className="custom-outline-btn" onClick={toggleEngineDropdown}>+</Button>
    </div>
</div>

{/* Dropdown list */}
{showEngineDropdown && (
    <div ref={engineDropdownRef} className="dropdown-container mt-2">
        <Form.Select onChange={handleAddEngine}>
            {Object.entries(carData.engine_type)
                .filter(([engineValue]) => !filters.engine.includes(engineValue))
                .map(([engineValue, engineDisplay]) => (
                    <option key={engineValue} value={engineValue}>
                        {engineDisplay}
                    </option>
                ))}
        </Form.Select>
    </div>
)}

{/* Selected engines */}
{filters.engine.length > 0 && (
    <div className="selected-models d-flex flex-wrap mt-3">
        {filters.engine.map((engine, index) => (
            <div key={index} className="selected-model d-flex align-items-center mb-2">
                <span>{carData.engine_type[engine]}</span>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleRemoveEngine(engine)}
                >
                    &times;
                </button>
            </div>
        ))}
    </div>
)}

                            {/* Gearbox */}
                            <div className="input-container">
                                <label htmlFor="gearBoxSelect" className="floating-label">Gearbox:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Choose the type of gearbox (transmission) you prefer, such as manual or automatic.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                <Form.Select name='gearBox' value={filters.gearBox} onChange={handleChange}>
                                {Object.entries(carData.gearbox).map(([value, text]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                </Form.Select>
                            </div>
                            </div>

                         {/* Body Type */}
<div className="input-container">
    <label htmlFor="bodyTypeSelect" className="floating-label">Body Type:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Select the body style of the car, like sedan, SUV, or hatchback.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Button className="custom-outline-btn" onClick={toggleBodyTypeDropdown}>+</Button>
    </div>
</div>

{/* Dropdown list */}
{showBodyTypeDropdown && (
    <div ref={bodyTypeDropdownRef} className="dropdown-container mt-2">
        <Form.Select onChange={handleAddBodyType}>
            {Object.entries(carData.body_type)
                .filter(([bodyTypeValue]) => !filters.bodyType.includes(bodyTypeValue))
                .map(([bodyTypeValue, bodyTypeDisplay]) => (
                    <option key={bodyTypeValue} value={bodyTypeValue}>
                        {bodyTypeDisplay}
                    </option>
                ))}
        </Form.Select>
    </div>
)}

{/* Selected body types */}
{filters.bodyType.length > 0 && (
    <div className="selected-models d-flex flex-wrap mt-3">
        {filters.bodyType.map((bodyType, index) => (
            <div key={index} className="selected-model d-flex align-items-center mb-2">
                <span>{carData.body_type[bodyType]}</span>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleRemoveBodyType(bodyType)}
                >
                    &times;
                </button>
            </div>
        ))}
    </div>
)}

                            {/* Color */}
<div className="input-container">
    <label htmlFor="colorSelect" className="floating-label">Color:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Pick the color of the car you're looking for, like black, white, or red.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Button className="custom-outline-btn" onClick={toggleColorDropdown}>+</Button>
    </div>
</div>

{/* Dropdown list */}
{showColorDropdown && (
    <div ref={colorDropdownRef} className="dropdown-container mt-2">
        <Form.Select onChange={handleAddColor}>
            {Object.entries(carData.color)
                .filter(([colorValue]) => !filters.color.includes(colorValue))
                .map(([colorValue, colorDisplay]) => (
                    <option key={colorValue} value={colorValue}>
                        {colorDisplay}
                    </option>
                ))}
        </Form.Select>
    </div>
)}

{/* Selected colors */}
{filters.color.length > 0 && (
    <div className="selected-models d-flex flex-wrap mt-3">
        {filters.color.map((color, index) => (
            <div key={index} className="selected-model d-flex align-items-center mb-2">
                <span>{carData.color[color]}</span>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleRemoveColor(color)}
                >
                    &times;
                </button>
            </div>
        ))}
    </div>
)}

                        {/* Area */}
<div className="input-container">
    <label htmlFor="areaSelect" className="floating-label">Area:</label>
    <OverlayTrigger
        placement="top"
        overlay={<Tooltip>Select the location where you want to find cars, such as a city or region.</Tooltip>}
    >
        <InfoCircle className="ms-2 info-icon" />
    </OverlayTrigger>
    <div className="input-group mt-2">
        <Button className="custom-outline-btn" onClick={toggleAreaDropdown}>+</Button>
    </div>
</div>

{/* Dropdown list */}
{showAreaDropdown && (
    <div ref={areaDropdownRef} className="dropdown-container mt-2">
        <Form.Select onChange={handleAddArea}>
            {Object.entries(carData.area)
                .filter(([areaValue]) => !filters.area.includes(areaValue))
                .map(([areaValue, areaDisplay]) => (
                    <option key={areaValue} value={areaValue}>
                        {areaDisplay}
                    </option>
                ))}
        </Form.Select>
    </div>
)}

{/* Selected areas */}
{filters.area.length > 0 && (
    <div className="selected-models d-flex flex-wrap mt-3">
        {filters.area.map((area, index) => (
            <div key={index} className="selected-model d-flex align-items-center mb-2">
                <span>{carData.area[area]}</span>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleRemoveArea(area)}
                >
                    &times;
                </button>
            </div>
        ))}
    </div>
)}

                            {/* Deal Type */}
                            <div className="input-container">
                                <label htmlFor="dealTypeSelect" className="floating-label">Deal type:</label>
                                <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Choose the type of deal you're interested in, such as buying, leasing, or renting the car.</Tooltip>}
                                    >
                                        <InfoCircle className="ms-2 info-icon" />
                                    </OverlayTrigger>
                                <div className="input-group">
                                    <Form.Select name='dealType' value={filters.dealType} onChange={handleChange}>
                                        {Object.entries(carData.deal_type).map(([value, text]) => (
                                            <option key={value} value={value}>{text}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>

                            {/* Run Distance */}
                            <div className="input-container">
                                <label htmlFor="runInput" className="floating-label">Run Distance:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Specify the minimum and maximum kilometers the car has run.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <div className="input-group">
                                    <FloatingLabel controlId="floatingRunFrom" label="From">
                                        <Form.Control id='cool-control-left' type="number" name="runMin" value={filters.runMin} onChange={handleChange} />
                                    </FloatingLabel>
                                    <span className="input-group-text">-</span>
                                    <FloatingLabel controlId="floatingRunTo" label="To">
                                        <Form.Control id='cool-control-right' type="number" name="runMax" value={filters.runMax} onChange={handleChange} />
                                    </FloatingLabel>
                                </div>
                            </div>

                            {/* Checkup */}
                            <Form.Group className="mb-3" controlId="checkUpMonthSelect">
                                <label htmlFor="checkupSelect" className="floating-label">Checkup valid until:</label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Select the latest valid checkup date. Choosing "No Preference" will show cars with or without a valid checkup.</Tooltip>}
                                >
                                    <InfoCircle className="ms-2 info-icon" />
                                </OverlayTrigger>
                                <Form.Select name="checkup" value={filters.checkup || ''} onChange={handleChange}>
                                    <option value="">No Preference</option>
                                    {generateCheckUpMonths().map((month) => (
                                        <option key={month.value} value={month.value}>{month.text}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            {/* Buttons */}
                            <Row>
                                <Col xs={8} sm={9} md={10} xxl={11} className="mt-3">
                                    <Button variant="primary" className="btn-save" onClick={handleValidation} type='button'>
                                        {isSaving ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                Saving...
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Col>
                                <Col xs={4} sm={3} md={2} xxl={1} className="mt-3">
                                    <Button variant="danger" className="btn-delete" onClick={() => setShowDeleteModal(true)}>Delete</Button>
                                </Col>
                            </Row>
                            {errorMessage && <div className="error-message text-center">{errorMessage}</div>}
                            {/* Confirm Modal */}
                            <Modal className="delete-modal" show={showDeleteModal} onHide={() => {
                                setShowDeleteModal(false);
                                setIsDeleting(false);
                            }}>
                                <Modal.Header closeButton className='delete-modal-header'>
                                    <Modal.Title>Delete Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete <b>{filters.filterName}</b> filter?</Modal.Body>
                                <Modal.Footer>
                                    {!isDeleting && (
                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                            Cancel
                                        </Button>
                                    )}
                                    <Button variant="danger" onClick={handleDelete}>
                                        {isDeleting ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                Deleting...
                                            </>
                                        ) : 'Confirm'}
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Accordion.Body>
                    </Accordion.Item>
                </form>
            </div>
        </div>
    );
}

export default FiltersForm;
