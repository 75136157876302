import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useMediaQuery } from 'react-responsive';
import './styles/navbar.scss';
import logo from './img/sw_logo-white.svg';
import burgerIcon from './img/burger_menu-white.svg';

function NavigationBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();  // Track the current page
  const isMobile = useMediaQuery({ query: '(max-width: 1199px)' });

  const AuthLinks = () => (
    <>
      {isMobile ? (
        <>
          <Link to='/profile' className="nav-link">Profile</Link>
          <span className='horizontal-divider'></span>
          <a href="/api/auth/signout" className="nav-link">Sign out</a>
        </>
      ) : (
        <NavDropdown align="end" title="Account" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to='/profile'>Profile</NavDropdown.Item>
          <span className='horizontal-divider'></span>
          <NavDropdown.Item as="a" href="/api/auth/signout">Sign out</NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  );

  const GuestLinks = () => (
    <>
      {isMobile ? (
        <>
          <Link to='/login' className="nav-link">Login</Link>
          <span className='horizontal-divider'></span>
          <Link to='/signup' className="nav-link">Sign up</Link>
        </>
      ) : (
        <NavDropdown align="end" title="Account" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to='/login'>Login</NavDropdown.Item>
          <span className='horizontal-divider'></span>
          <NavDropdown.Item as={Link} to='/signup'>Sign up</NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  );

  useEffect(() => {
    const fetchAuthStatus = async () => {
      setIsLoading(true);
      try {
        const res = await fetch('/api/auth/status');
        const data = await res.json();
        setIsLoggedIn(data.isLoggedIn);
      } catch (error) {
        console.error('Error fetching authentication status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuthStatus(); 
  }, []);

  // Conditionally show the dropdown when on specific pages
  const showDropdown = ['/filters', '/motorcycles', '/spare-parts'].includes(location.pathname);

  return (
    <Navbar expand="xl" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand>
          <Link to='/' className="nav-link" aria-label="Homepage">
            <img src={logo} alt="StalkerWind" className="nav-logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-xl-none">
          <img src={burgerIcon} alt="Menu" style={{ width: '24px', height: '24px' }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Conditionally render Products as dropdown or link */}
            {showDropdown ? (
              <NavDropdown title="Products" id="products-dropdown"> {/* Products is a dropdown */}
                <NavDropdown.Item as={Link} to="#">
                  Transport
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item 
                  as={Link} 
                  to="/filters" 
                  className={location.pathname === '/filters' ? 'active-item' : ''}
                >
                  Cars
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={Link} 
                  to="/motorcycles" 
                  className={location.pathname === '/motorcycles' ? 'active-item' : ''}
                  disabled
                >
                  Motorcycles
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={Link} 
                  to="/spare-parts" 
                  className={location.pathname === '/spare-parts' ? 'active-item' : ''}
                  disabled
                >
                  Spare Parts
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Link to='/products' className="nav-link">Products</Link>
            )}
            <Link to='/feedback' className="nav-link">Feedback</Link>
          </Nav>
          <Nav>
            {isLoading ? (
              <span className="nav-link">Loading...</span>
            ) : (
              isLoggedIn ? <AuthLinks /> : <GuestLinks />
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
